<template>
	<div class="embed">
		<div class="button-container">
			<button type="button" @click="$router.push('/')">Back to editor</button>
		</div>
		<div class="content-container">
			<h1 class="title">How To Embed Releasecat</h1>

			<h2 class="step-title">To embed Releasecat pop-up posts on your website</h2>

			<div class="step">
				<label class="">1. Copy the code:</label>

				<div class="code-container">
					<div ref="mainCode" class="main-code"></div>
					<button ref="mainCodeButton" type="button" @click="copyMainCode">Copy</button>
				</div>
			</div>
			<div class="step">
				<label>2. Paste it inside head tag in your HTML page.</label>
			</div>

			<h2 class="step-title">To show manually Releasecat side panel (e.g. from 'what's new' menu)</h2>

			<div class="step">
				<label>Just execute:</label>

				<div class="code-container">
					<div ref="sidePanelCode" class="side-panel-code"></div>
					<button ref="sidePanelCodeButton" type="button" @click="copySidePanelCode">Copy</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import loader from "@monaco-editor/loader";
import copy from 'copy-to-clipboard';

export default {
	name: 'EmbedModal',
	data: () => ({
		editorOptions: {
			language: "html",
			minimap: { enabled: false },
			lineNumbers: 'on',
			fontSize: 16,
			contextmenu: false,
			scrollbar: {
				vertical: 'hidden'
			},
			scrollBeyondLastLine: false,
			lineNumbers: 'off',
			glyphMargin: false,
			folding: false,
			lineDecorationsWidth: 0,
			lineNumbersMinChars: 0,
			readOnly: true,
			overviewRulerLanes: 0,
			hideCursorInOverviewRuler: true,
			overviewRulerBorder: false,
			automaticLayout: true
		}
	}),
	computed: {
		activeStream() {
			return this.$store.getters.activeStream.attributes;
		},
		mainCode() {
			return '<script>' 
			+ '\n\tvar releasecat = {'
			+ '\n\t\tid: \'' + this.activeStream.uid + '\','
			+ '\n\t\tproduction: false // Change to \'true\' when you finish testing.'
			+ '\n\t};'
			+ '\n\tvar script = document.createElement(\'script\');'
			+ '\n\tscript.type = \'text/javascript\';'
			+ '\n\tscript.src = \'https://www.releasecat.io/embed/index.js\';'
			+ '\n\tscript.defer = true;'
			+ '\n\tdocument.head.appendChild(script);'
			+ '\n<\/script>';
		},
		sidePanelCode() {
			return 'Releasecat.showSidePanel();'
		}
	},
	methods: {
		copyMainCode() {
			copy(this.mainCode);
			this.$refs.mainCodeButton.innerHTML = 'Copied';
			setTimeout(() => {
				this.$refs.mainCodeButton.innerHTML = 'Copy';
			}, 1000);
		},
		copySidePanelCode() {
			copy(this.sidePanelCode);
			this.$refs.sidePanelCodeButton.innerHTML = 'Copied';
			setTimeout(() => {
				this.$refs.sidePanelCodeButton.innerHTML = 'Copy';
			}, 1000);
		},

		closeModal() {
			this.$emit('close');
		}
	},
	created() {
		if (!this.activeStream) {
			this.$router.push('/');
		}
	},
	mounted() {
		loader.init().then((monaco) => {
			monaco.editor.defineTheme('fieldDefault', {
				base: 'vs',
				inherit: true,
				rules: [{ background: 'ffffff' }],
				colors: {
					'editor.background': '#f7f7f7',
					'editor.foreground': '#41494d',
					'editor.lineHighlightBackground': '#f7f7f7'
				}
			});
			monaco.editor.setTheme('fieldDefault');

			monaco.editor.create(this.$refs.mainCode, {
				...this.editorOptions,
				value: this.mainCode
			});
			monaco.editor.create(this.$refs.sidePanelCode, {
				...this.editorOptions,
				value: this.sidePanelCode
			});
		});
	}
}
</script>

<style lang="sass">
@import '../assets/sass/_variables'
@import '../assets/sass/_mixins'

.embed
	.button-container,
	.content-container
		width: 800px
		margin: 20px auto
	.button-container
		button
			font-size: 16px
			text-transform: uppercase
			color: $accent-color
			transition: .1s linear
			cursor: pointer
			&::before
				content: '<-'
				margin-right: 5px
			&:hover
				color: $accent-active-color
	.content-container
		border: 1px solid $border-color
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
		padding: 40px 60px
		.title
			font-size: 28px
			font-weight: bold
			text-align: center
			text-transform: uppercase
			color: #afb9c7
		.step-title
			font-weight: normal
			font-size: 20px
			margin-top: 40px
			border-bottom: 1px solid #d7d7d7
			padding-bottom: 10px
			color: #111
		.step
			margin-top: 20px
		label
			display: inline-block
			margin-bottom: 10px
		.code-container
			position: relative
			padding: 10px 20px
			background: #f7f7f7
			button
				position: absolute
				z-index: 999
				right: 20px
				top: 10px
				background: $accent-color
				font-size: 16px
				padding: 5px 20px
				color: #fff
				box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1)
				transition: .1s linear
				&:hover
					cursor: pointer
					background: $accent-active-color
		.main-code
			width: 100%
			height: 265px
		.side-panel-code
			width: 100%
			height: 30px

@media screen and (max-width: 1000px)
	.embed
		.button-container,
		.content-container
			width: calc(100% - 40px)

@media screen and (max-width: 700px)
	.embed
		.button-container,
		.content-container
			width: calc(100% - 40px)
		.button-container
			button
				font-size: 16px
		.content-container
			padding: 20px
			.title
				font-size: 18px
			.step-title
				font-size: 16px
				margin-top: 30px
</style>

<!-- On window resize -->